import { useStaticQuery, graphql } from 'gatsby';

export interface ContactProps {
  name: string;
  photo: {
    file: {
      url: string;
    };
  };
  position: string;
  telephone: string;
  email: string;
}

interface SectionContactUsQuery {
  contentfulContactUs: {
    title: string;
    contacts: Array<ContactProps>;
  };
}

const GET_SECTION_CONTACT_US = graphql`
  query SectionContactUS {
    contentfulContactUs {
      title
      contacts {
        name
        photo {
          file {
            url
          }
        }
        position
        telephone
        email
      }
    }
  }
`;

const useSectionContactUsQuery = () => {
  const { contentfulContactUs } = useStaticQuery<SectionContactUsQuery>(GET_SECTION_CONTACT_US);
  return contentfulContactUs;
};

export default useSectionContactUsQuery;
