import { useStaticQuery, graphql } from 'gatsby';

export interface ArticleProps {
  title: string;
  content: {
    raw: string;
  };
  createdAt: string;
  mainImage: {
    file: {
      url: string;
      fileName: string;
    };
  };
  slug: string;
}
interface SectionArticlesQueryResults {
  allContentfulArticle: {
    nodes: Array<ArticleProps>;
  };
}

const GET_SECTION_ARTICLES = graphql`
  query SectionArticles {
    allContentfulArticle {
      nodes {
        title
        content {
          raw
        }
        slug
        createdAt
        mainImage {
          file {
            url
            fileName
          }
        }
      }
    }
  }
`;

const useSectionArticlesQuery = () => {
  const { allContentfulArticle } =
    useStaticQuery<SectionArticlesQueryResults>(GET_SECTION_ARTICLES);
  return allContentfulArticle;
};

export default useSectionArticlesQuery;
