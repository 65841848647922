import React from 'react';
import { Heading } from '..';
import {
  ContactPhoto,
  ContactWrapper,
  JobPosition,
  TextWrapper,
  PhoneNumber,
} from './ContactUs.styled';

interface ContactUsProps {
  name: string;
  position: string;
  telephone: string;
  photo: string;
  email: string;
}

const ContactUs = ({ name, position, telephone, photo, email }: ContactUsProps) => (
  <ContactWrapper>
    <ContactPhoto alt={name} src={photo} />
    <TextWrapper>
      <Heading variant="h3">
        {email ? <a href={`mailto:${email}`} style={{textDecoration: 'none', color: 'white'}}>{name}</a> : name}
      </Heading>
      <JobPosition>{position}</JobPosition>
      <PhoneNumber href={`tel:${telephone}`}>{telephone}</PhoneNumber>
    </TextWrapper>
  </ContactWrapper>
);

export default ContactUs;
