import styled from 'styled-components';

export const SectionFooter = styled.div`
  margin: 0;
  padding: 0;
`;
export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-top: 1px solid ${({ theme }) => theme.palette.darkGrey};
  padding: 30px 15px 10px;
  margin: 0 -15px;

  ${({ theme }) => theme.up(theme.breakpoints.sm)} {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    padding: 30px 0;
    margin: 0;
  }

  ${({ theme }) => theme.up(theme.breakpoints.lg)} {
    padding: 30px 100px;
  }
`;

export const SocialIconsWrapper = styled.div`
  margin: 30px 0 0 0;
  ${({ theme }) => theme.up(theme.breakpoints.sm)} {
    margin: 0 0 0 30px;
  }
`;
