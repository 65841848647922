import React, { useEffect, useState } from 'react';
import {
  AppLogo,
  TopBarWrapper,
  MobileBarWrapper,
  HamburgerWrapper,
  MobileBarContent,
  DesktopBarWrapper,
} from './TopBar.styled';
import { Button, Menu } from '..';
import { useTopBarQuery } from '../../hooks';
import { Hamburger } from '../../assets/icons';

interface BarProps {
  menuLinks: Array<{
    url: string;
    text: string;
  }>;
  orderText: string;
  location: Location;
  // eslint-disable-next-line react/no-unused-prop-types
  orderUrl: string;
}

interface TopBarProps {
  location: Location;
}

const DesktopBar = ({ menuLinks, orderText, location, orderUrl }: BarProps) => (
  <DesktopBarWrapper>
    {menuLinks && <Menu location={location} menuLinks={menuLinks} />}
    <Button url={orderUrl}>{orderText}</Button>
  </DesktopBarWrapper>
);

const MobileBar = ({ menuLinks, orderText, location, orderUrl }: BarProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    }
  }, [isOpen]);

  return (
    <MobileBarWrapper>
      <HamburgerWrapper isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <Hamburger />
      </HamburgerWrapper>
      <MobileBarContent isOpen={isOpen}>
        {menuLinks && <Menu location={location} menuLinks={menuLinks} />}
        <Button url={orderUrl}>{orderText}</Button>
      </MobileBarContent>
    </MobileBarWrapper>
  );
};

const TopBar = ({ location }: TopBarProps) => {
  const { logo, menuLinks, orderJetson } = useTopBarQuery();
  const { text: orderText, url } = orderJetson;
  const logoUrl = logo.file.url;

  return (
    <TopBarWrapper>
      <AppLogo to="/">
        <img alt="Jetson" height={50} src={logoUrl} width={153} />
      </AppLogo>
      {/*<MobileBar location={location} menuLinks={menuLinks} orderText={orderText} orderUrl={url} />*/}
      {/*<DesktopBar location={location} menuLinks={menuLinks} orderText={orderText} orderUrl={url} />*/}
    </TopBarWrapper>
  );
};

export default TopBar;
