import { useStaticQuery, graphql } from 'gatsby';

export interface BuildSlot {
  status: string;
  location: string;
  date: string;
  number: string;
}

interface OrderYourOwnSectionQueryResults {
  contentfulSectionOrderYourOwn: {
    title: string;
    description: {
      raw: string;
    };
    button: {
      text: string;
      url: string;
    };
    image: {
      file: {
        fileName: string;
        url: string;
      };
    };
    buildSlots: Array<BuildSlot>;
  };
}

const GET_SECTION_ORDER_YOUR_OWN = graphql`
  query OrderYourOwnSection {
    contentfulSectionOrderYourOwn {
      description {
        raw
      }
      title
      button {
        text
        url
      }
      buildSlots {
        status
        location
        date
        number
      }
      image {
        file {
          fileName
          url
        }
      }
    }
  }
`;

const useSectionOrderYourOwnQuery = () => {
  const { contentfulSectionOrderYourOwn } = useStaticQuery<OrderYourOwnSectionQueryResults>(
    GET_SECTION_ORDER_YOUR_OWN,
  );
  return contentfulSectionOrderYourOwn;
};

export default useSectionOrderYourOwnQuery;
