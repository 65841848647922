import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.white};
  text-decoration: none;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -20px;
    width: 2px;
    opacity: 0;
    transition: opacity 0.6s;
    background: ${({ theme }) => theme.palette.orange};
  }

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      top: initial;
      width: 100%;
      height: 2px;
      opacity: 0;
      transition: opacity 0.6s;
    }
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
`;

export default StyledLink;
