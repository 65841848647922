import React from 'react';
import {
  SocialBarInnerWrapper,
  SocialBarWrapper,
  SocialIconsWrapper,
  SocialIcon,
  SocialIconLink,
} from './SocialBar.styled';
import { useSectionOneQuery } from '../../hooks';

const SocialBar = ({ isHorizontal = false }) => {
  const { socialBar } = useSectionOneQuery();
  return (
    <SocialBarWrapper isHorizontal={isHorizontal}>
      <SocialBarInnerWrapper isHorizontal={isHorizontal}>
        <SocialIconsWrapper isHorizontal={isHorizontal}>
          {socialBar.map(
            ({
              url: socialUrl,
              icon: {
                file: { url: socialIcon },
              },
            }) => (
              <SocialIconLink
                key={socialUrl}
                href={socialUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                <SocialIcon key={socialIcon} src={socialIcon} />
              </SocialIconLink>
            ),
          )}
        </SocialIconsWrapper>
      </SocialBarInnerWrapper>
    </SocialBarWrapper>
  );
};
export default SocialBar;
