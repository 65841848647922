import styled from 'styled-components';
import { Link } from 'gatsby';
import { StyledButtonText } from '../Button/Button.styled';
import jetsonBackground from '../../assets/images/mobile_menu_background.png';

export const TopBarWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${StyledButtonText} {
    z-index: 2;
  }
`;

export const AppLogo = styled(Link)``;

interface HamburgerWrapperProps {
  isOpen: boolean;
}

export const HamburgerWrapper = styled.div<HamburgerWrapperProps>`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    ${({ isOpen, theme }) =>
      isOpen &&
      `
    background: ${theme.palette.orange};
  `}
  }
`;

export const MobileBarWrapper = styled.div`
  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    display: none;
  }
`;

interface MobileBarContentProps {
  isOpen: boolean;
}

export const MobileBarContent = styled.div<MobileBarContentProps>`
  position: fixed;
  right: -200vw;
  width: 100%;
  height: 100vh;
  padding: 40px 30px;
  transition: 0.3s all;
  top: 250px;
  background: url(${jetsonBackground}) center left no-repeat,
    radial-gradient(90.67% 56.67% at 46.97% 39.67%, #515658 0%, rgba(70, 73, 74, 0) 82%);
  background-size: contain, cover;
  background-color: ${({ theme }) => theme.palette.black};
  z-index: 10;

  ${({ isOpen }) =>
    isOpen &&
    `
    right: 0;
    top: 75px;
  `}
`;

export const DesktopBarWrapper = styled.div`
  display: none;
  z-index: 2;
  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(50% + 248px);
  }
`;
