import styled from 'styled-components';

export const TextInput = styled.input`
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.darkGrey};
  background: transparent;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.white};
  font-family: Rosario, Bebas Neue, georgia, serif, sans-serif;
  padding: 10px;

  &::placeholder {
    color: ${({ theme }) => theme.palette.white};
    font-family: Rosario, Bebas Neue, georgia, serif, sans-serif;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px black inset; /* Change the color to your own background color */
    box-shadow: 0 0 0 50px black inset;
    -webkit-text-fill-color: ${({ theme }) => theme.palette.white};
    font-family: Rosario, Bebas Neue, georgia, serif, sans-serif;
  }
`;

export const TextAreaInput = styled.textarea`
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.darkGrey};
  background: transparent;
  resize: none;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.white};
  font-family: Rosario, Bebas Neue, georgia, serif, sans-serif;
  padding: 10px;

  &::placeholder {
    color: ${({ theme }) => theme.palette.white};
    font-family: Rosario, Bebas Neue, georgia, serif, sans-serif;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px black inset; /* Change the color to your own background color */
    box-shadow: 0 0 0 50px black inset;
    -webkit-text-fill-color: ${({ theme }) => theme.palette.white};
    font-family: Rosario, Bebas Neue, georgia, serif, sans-serif;
  }
`;
