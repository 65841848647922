import styled from 'styled-components';

export const Numbers = styled.p`
  line-height: 33px;
  font-weight: 400;
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: 25px;
  color: ${({ theme }) => theme.palette.orange};
  padding-right: 30px;

  margin: 0;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    line-height: 33px;
    color: ${({ theme }) => theme.palette.orange};
    padding-right: 0;
    margin: 0 15px;
  }
`;

export const Location = styled.div`
  text-align: start;
  margin: 0;
  color: ${({ theme }) => theme.palette.grey};
  font-size: 13px;
  line-height: 25px;
  font-family: Rosario, Bebas Neue, georgia, serif, sans-serif;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    text-align: end;
    margin: 0;
    color: ${({ theme }) => theme.palette.grey};
    font-size: 18px;
    line-height: 25px;
    font-family: Rosario, Bebas Neue, georgia, serif, sans-serif;
  }
`;

export const Avaliable = styled.div`
  text-align: end;
  color: ${({ theme }) => theme.palette.orange};
  font-size: 20px;
  margin-top: 5px;
`;
