import { useStaticQuery, graphql } from 'gatsby';

interface SectionStartAndLandQueryResults {
  contentfulSectionStartAndLand: {
    title: string;
    subtitle: string;
    videoId: string;
  };
}

const GET_SECTION_START_AND_LAND = graphql`
  query SectionStartAndLand {
    contentfulSectionStartAndLand {
      title
      subtitle
      videoId
    }
  }
`;

const useSectionStartAndLandQuery = () => {
  const { contentfulSectionStartAndLand } = useStaticQuery<SectionStartAndLandQueryResults>(
    GET_SECTION_START_AND_LAND,
  );
  return contentfulSectionStartAndLand;
};

export default useSectionStartAndLandQuery;
