import { useStaticQuery, graphql } from 'gatsby';

interface SectionOneQueryResults {
  contentfulSection1: {
    title: string;
    upperTitle?: string;
    subtitle: {
      subtitle: string;
    };
    socialBar: Array<{
      id: string;
      url: string;
      icon: {
        file: {
          url: string;
        };
      };
    }>;
    button: {
      url: string;
      text: string;
    };
    perks: Array<{
      value: string;
      title: string;
    }>;
  };
}
const GET_SECTION_ONE = graphql`
  query SectionOne {
    contentfulSection1 {
      title
      upperTitle
      subtitle {
        subtitle
      }
      socialBar {
        url
        icon {
          file {
            url
          }
        }
      }
      button {
        url
        text
      }
      perks {
        value
        title
      }
    }
  }
`;

const useSectionOneQuery = () => {
  const { contentfulSection1 } = useStaticQuery<SectionOneQueryResults>(GET_SECTION_ONE);
  return contentfulSection1;
};

export default useSectionOneQuery;
