import { useStaticQuery, graphql } from 'gatsby';

export interface TopBarQueryResults {
  contentfulTopBar: {
    logo: {
      id: string;
      file: {
        url: string;
      };
    };
    menuLinks: Array<{
      url: string;
      text: string;
    }>;
    orderJetson: {
      url: string;
      text: string;
    };
  };
}

const GET_TOPBAR = graphql`
  query TopBar {
    contentfulTopBar {
      menuLinks {
        url
        text
      }
      logo {
        id
        file {
          url
        }
      }
      orderJetson {
        url
        text
      }
    }
  }
`;

const useTopBarQuery = () => {
  const { contentfulTopBar } = useStaticQuery<TopBarQueryResults>(GET_TOPBAR);
  return contentfulTopBar;
};

export default useTopBarQuery;
