import styled from 'styled-components';
import React, { memo, useRef } from 'react';
import useOnScreen from '../../../../hooks/useOnScreen';

export const StarsWrapper = styled.div`
  position: absolute;
  width: 679px;
  height: 1126px;
  top: -53%;
  right: 10%;
  pointer-events: none;

  svg .svg-elem-1 {
    stroke-dashoffset: 4682.71337890625px;
    stroke-dasharray: 4682.71337890625px;
    -webkit-transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
    transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
  }

  svg.active .svg-elem-1 {
    stroke-dashoffset: 9365.4267578125px;
  }

  svg .svg-elem-2 {
    stroke-dashoffset: 4687.7412109375px;
    stroke-dasharray: 4687.7412109375px;
    -webkit-transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
    transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
  }

  svg.active .svg-elem-2 {
    stroke-dashoffset: 9375.482421875px;
  }
`;

const StarTwo = () => {
  const starRef = useRef<SVGSVGElement>(null);
  const isStarInView = useOnScreen(starRef);
  return (
    <StarsWrapper>
      <svg
        ref={starRef}
        className={isStarInView ? 'active' : ''}
        fill="none"
        height="100%"
        viewBox="0 0 879 1481"
        width="879"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="svg-elem-1"
          d="M711.875 1429.89L662.695 1183.53H121.717L711.875 887.897L1105.31 1L1031.54 887.897L1449.57 1183.53H908.595L711.875 1429.89Z"
          stroke="#EA9B3B"
        />
        <path
          className="svg-elem-2"
          d="M591.108 1478.1L591.318 1479.15L591.989 1478.31L788.558 1232.14H1329.3H1330.87L1329.59 1231.23L911.789 935.764L985.536 49.1523L984.58 48.9081L591.214 935.641L1.21576 1231.19L1.4397 1232.14H542.008L591.108 1478.1Z"
          opacity="0.21"
          stroke="white"
        />
      </svg>
    </StarsWrapper>
  );
};

export default memo(StarTwo);
