import styled from 'styled-components';
import { Link } from 'gatsby';

export const StyledButton = styled.div`
  width: auto;
  height: 50px;
  padding: 16px 20px;
  color: ${({ theme }) => theme.palette.white};
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 2.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.palette.white};

  &:hover,
  &:active {
    text-decoration: none;
  }
`;

export const StyledButtonArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-size: 50%;
  border: 1px solid ${({ theme }) => theme.palette.white};
  border-left: none;
  cursor: pointer;
`;

export const StyledButtonLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  &:hover {
    ${StyledButton}, ${StyledButtonArrow} {
      background: ${({ theme }) => theme.palette.orange};
      border-color: ${({ theme }) => theme.palette.orange};
    }
  }
`;

export const StyledButtonText = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;

  &:hover {
    ${StyledButton}, ${StyledButtonArrow} {
      background: ${({ theme }) => theme.palette.orange};
      border-color: ${({ theme }) => theme.palette.orange};
    }
  }
`;

export const StyledSubmitButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-family: Bebas Neue, georgia, serif, sans-serif;
  cursor: pointer;

  &:hover {
    ${StyledButton}, ${StyledButtonArrow} {
      background: ${({ theme }) => theme.palette.orange};
      border-color: ${({ theme }) => theme.palette.orange};
    }
  }
`;
