import { useStaticQuery, graphql } from 'gatsby';

export interface FeatureProps {
  title: string;
  value: string;
}
interface SectionKeyFeaturesQueryResults {
  contentfulSectionTechSpec: {
    entryTitle: string;
    techSpecPerks: Array<FeatureProps>;
  };
}

const GET_SECTION_TECH_SPEC = graphql`
  query SectionTechSpec {
    contentfulSectionTechSpec {
      entryTitle
      techSpecPerks {
        title
        value
      }
    }
  }
`;

const useSectionTechSpecQuery = () => {
  const { contentfulSectionTechSpec } =
    useStaticQuery<SectionKeyFeaturesQueryResults>(GET_SECTION_TECH_SPEC);
  return contentfulSectionTechSpec;
};

export default useSectionTechSpecQuery;
