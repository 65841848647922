import React, { ReactNode } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Node } from '@contentful/rich-text-types';
import { HeadingVariant } from '../Heading/Heading';
import { Heading } from '../index';
import { RichTextRenderedWrapper } from './RichTextRendered.styled';

interface RichTextRendererProps {
  children?: {
    raw: string;
  };
}

const renderStyledHeader = (variant: HeadingVariant) => (node: Node, renderChildren: ReactNode) =>
  <Heading variant={variant}>{renderChildren}</Heading>;

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: renderStyledHeader('h1'),
    [BLOCKS.HEADING_2]: renderStyledHeader('h2'),
    [BLOCKS.HEADING_3]: renderStyledHeader('h3'),
    [BLOCKS.HEADING_4]: renderStyledHeader('h4'),
    [BLOCKS.HEADING_5]: renderStyledHeader('h5'),
    [BLOCKS.HEADING_6]: renderStyledHeader('h6'),
  },
  renderText: (text: string) =>
    text.split('\n').reduce<string[]>((children: string[], textSegment: string, index: number) => {
      // eslint-disable-next-line react/no-array-index-key
      return [...children, index > 0 && <br key={index} />, textSegment] as string[];
    }, []),
};

const RichTextRenderer = ({ children }: RichTextRendererProps) => {
  if (!children) return null;

  return (
    <RichTextRenderedWrapper>
      {documentToReactComponents(JSON.parse(children.raw), options)}
    </RichTextRenderedWrapper>
  );
};

export default RichTextRenderer;
