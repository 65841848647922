import styled from 'styled-components';

export const RichTextRenderedWrapper = styled.div`
  font-family: Rosario, Bebas Neue, georgia, serif, sans-serif;
  font-size: 18px;
  line-height: 29px;
  color: ${({ theme }) => theme.palette.grey};

  b {
    font-weight: 900 !important;
  }
`;
