import styled from 'styled-components';
import React, { memo, useRef } from 'react';
import useOnScreen from '../../../../hooks/useOnScreen';

export const StarsWrapper = styled.div`
  position: absolute;
  width: 758px;
  height: 1626px;
  top: -5%;
  left: 150px;
  pointer-events: none;
  z-index: -100;

  svg .svg-elem-1 {
    stroke-dashoffset: 3956.875px;
    stroke-dasharray: 3956.875px;
    -webkit-transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
    transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
  }

  svg.active .svg-elem-1 {
    stroke-dashoffset: 7913.75px;
  }

  svg .svg-elem-2 {
    stroke-dashoffset: 3961.916015625px;
    stroke-dasharray: 3961.916015625px;
    -webkit-transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
    transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
  }

  svg.active .svg-elem-2 {
    stroke-dashoffset: 7923.83203125px;
  }

  svg .svg-elem-3 {
    stroke-dashoffset: 3956.875px;
    stroke-dasharray: 3956.875px;
    -webkit-transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s;
    transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s;
  }

  svg.active .svg-elem-3 {
    stroke-dashoffset: 7913.75px;
  }

  svg .svg-elem-4 {
    stroke-dashoffset: 3961.916015625px;
    stroke-dasharray: 3961.916015625px;
    -webkit-transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s;
    transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s;
  }

  svg.active .svg-elem-4 {
    stroke-dashoffset: 7923.83203125px;
  }
`;

const StarThree = () => {
  const starRef = useRef<SVGSVGElement>(null);
  const isStarInView = useOnScreen(starRef);

  return (
    <StarsWrapper>
      <svg
        ref={starRef}
        className={isStarInView ? 'active' : ''}
        fill="none"
        height="100%"
        viewBox="0 0 515 1252"
        width="515"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="svg-elem-1"
          d="M-110.731 1208.31L-152.284 1000.16H-609.374L-110.731 750.367L221.698 1L159.368 750.1367L512.573 1000.16H55.4836L-110.731 1208.31Z"
          stroke="#EA9B3B"
        />
        <path
          className="svg-elem-2"
          d="M-212.847 1249.06L-212.637 1250.12L-211.966 1249.28L-45.9015 1041.31H410.948H412.521L411.236 1040.4L58.2639 790.774L120.571 41.6917L119.615 41.4475L-212.741 790.65L-711.224 1040.36L-711 1041.31H-254.32L-212.847 1249.06Z"
          opacity="0.21"
          stroke="white"
        />
        <path
          className="svg-elem-3"
          d="M-110.731 1208.31L-152.284 1000.16H-609.374L-110.731 750.367L221.698 1L159.368 750.367L512.573 1000.16H55.4836L-110.731 1208.31Z"
          stroke="#EA9B3B"
        />
        <path
          className="svg-elem-4"
          d="M-212.847 1249.06L-212.637 1250.12L-211.966 1249.28L-45.9015 1041.31H410.948H412.521L411.236 1040.4L58.2639 790.774L120.571 41.6917L119.615 41.4475L-212.741 790.65L-711.224 1040.36L-711 1041.31H-254.32L-212.847 1249.06Z"
          opacity="0.21"
          stroke="white"
        />
      </svg>
    </StarsWrapper>
  );
};

export default memo(StarThree);
