import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { Heading } from '..';
import { formatDate } from '../../helpers';
import { BuildSlot } from '../../hooks/api/useSectionOrderYourOwnQuery';
import './timeline.css';
import { Location, Numbers, Avaliable } from './Timeline.styled';

interface TimelineProps {
  items: Array<BuildSlot>;
}

const Timeline = ({ items }: TimelineProps) => (
  <VerticalTimeline>
    {items.map(({ status, location, date, number }) => (
      <VerticalTimelineElement key={`${number}${location}`}>
        {status === 'Slot Available' ? (
          <>
            <Numbers>{number}</Numbers>
            <Avaliable>{status}</Avaliable>
          </>
        ) : (
          <>
            <Numbers>{number}</Numbers>
            <Heading variant="h3">{formatDate(date)}</Heading>
            <Location>
              {`${status} `}
              {location}
            </Location>
          </>
        )}
      </VerticalTimelineElement>
    ))}
  </VerticalTimeline>
);

export default Timeline;
