import React, { ReactNode } from 'react';
import { H1, H2, H3, H4, H5, H6 } from './Heading.styled';

export type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface HeadingProps {
  variant: HeadingVariant;
  children: ReactNode;
}

const Heading = ({ variant, children }: HeadingProps) => {
  switch (variant) {
    case 'h1':
      return <H1>{children}</H1>;
    case 'h2':
      return <H2>{children}</H2>;
    case 'h3':
      return <H3>{children}</H3>;
    case 'h4':
      return <H4>{children}</H4>;
    case 'h5':
      return <H5>{children}</H5>;
    case 'h6':
      return <H6>{children}</H6>;
    default:
      return null;
  }
};

export default Heading;
