import styled from 'styled-components';
import { PAGE_WIDTH } from '../Theme';

export const AppContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${PAGE_WIDTH + 30}px;
  padding: 25px 20px;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    padding: 25px 60px;
  }
`;
