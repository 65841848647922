import styled from 'styled-components';
import { Image } from '..';

export const SingleArticleTileWrapper = styled.div`
  width: 100%;
  min-width: 0;

  h3 {
    margin: 30px 0 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 33px;
  }

  h6 {
    margin: 10px 0 20px;
    color: ${({ theme }) => theme.palette.grey};
  }
`;

export const SingleArticleImage = styled(Image)`
  filter: grayscale(100%);
  transition: all 0.5s ease;
  object-fit: cover;
  width: 100%;
  height: 243px;

  &:hover {
    filter: grayscale(0);
  }
`;

export const RichTextRendererWrapper = styled.div`
  height: 116px;
  overflow: hidden;
`;
