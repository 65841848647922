import React, { memo } from 'react';

const PulseDot = () => (
  <svg fill="none" height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.5" r="9.5" stroke="#EA9B3B" transform="matrix(1 0 0 -1 15 15)" />
    <circle opacity="0.3" r="14.5" stroke="#EA9B3B" transform="matrix(1 0 0 -1 15 15)" />
    <circle fill="#EA9B3B" r="5" transform="matrix(1 0 0 -1 15 15)" />
  </svg>
);

export default memo(PulseDot);
