import { useStaticQuery, graphql } from 'gatsby';

interface SectionTwoQueryResults {
  contentfulSection2: {
    title: string;
    buttonText: string;
    image: {
      file: {
        url: string;
      };
    };
    videoId: string;
    description: {
      raw: string;
    };
  };
}
const GET_SECTION_TWO = graphql`
  query SectionTwo {
    contentfulSection2 {
      image {
        file {
          url
        }
      }
      title
      videoId
      buttonText
      description {
        raw
      }
    }
  }
`;

const useSectionTwoQuery = () => {
  const { contentfulSection2 } = useStaticQuery<SectionTwoQueryResults>(GET_SECTION_TWO);
  return contentfulSection2;
};

export default useSectionTwoQuery;
