import React from 'react';
import { Paragraph, SocialBar } from '..';
import { FooterWrapper, SectionFooter, SocialIconsWrapper } from './Footer.styled';
import SectionContactUs from '../../layouts/sections/SectionContactUs';

const Footer = () => (
  <SectionFooter>
    <SectionContactUs />
    <FooterWrapper>
      <Paragraph style={{ fontSize: '16px' }}>
        Jetson is a registered trademark owned by Jetson AB | © {new Date().getFullYear()} Jetson AB
        | Do not use any of our brands without written approval.
      </Paragraph>
      <SocialIconsWrapper>
        <SocialBar isHorizontal />
      </SocialIconsWrapper>
    </FooterWrapper>
  </SectionFooter>
);

export default Footer;
