import styled from 'styled-components';
import { Paragraph } from '..';

export const PerksBarWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 20vh;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    padding-top: 0;
    margin: 150px 0 0 0;
    justify-content: center;
  }
  ${({ theme }) => theme.up(theme.breakpoints.lg)} {
    margin: 165px 110px 0 0;
    justify-content: flex-end;
  }
`;

export const PerkWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  h2 {
    font-size: 28px;
    ${({ theme }) => theme.up(theme.breakpoints.md)} {
      font-size: 40px;
    }
  }

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    margin: 0 45px;
    text-align: left;
  }

  ${Paragraph} {
    font-size: 14px;
    margin: 0 !important;
  }
`;
