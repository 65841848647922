import styled from 'styled-components';
import founderPhotoBackground from '../../assets/images/founder_photo_background.svg';

export const FounderWrapper = styled.div`
  text-align: center;
`;

export const JobPosition = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.palette.orange};
  margin-bottom: 23px;
`;

export const FounderPhoto = styled.img`
  width: 205px;
  height: 205px;
  padding: 30px;
  background: url(${founderPhotoBackground});
  background-size: cover;
  margin-bottom: 30px;
`;
