import styled from 'styled-components';

export const ContactWrapper = styled.div`
  text-align: start;
  display: flex;
  flex-direction: row;
  width: 400px;
`;

export const JobPosition = styled.p`
  font-size: 12px;
  line-height: 17px;
  color: ${({ theme }) => theme.palette.orange};
  margin-bottom: 2px;
  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    font-size: 14px;
    margin-bottom: 2px;
  }
`;

export const ContactPhoto = styled.img`
  width: 150px;
  height: 150px;
  padding: 30px;
  margin-bottom: -35px;
  object-fit: cover;
  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    margin-bottom: 30px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 10px;
  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    justify-content: center;
    margin-top: -27px;
  }
`;

export const PhoneNumber = styled.a`
  color: ${({ theme }) => theme.palette.grey};
  font-size: 18px;
  line-height: 25px;
  font-family: Rosario, Bebas Neue, georgia, serif, sans-serif;
  margin-top: -7px;
`;
