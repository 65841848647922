/* eslint-disable no-nested-ternary */
import React, { ReactNode } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Scrollbar } from 'swiper';

// Import Swiper styles
import 'swiper/css/bundle';
import 'swiper/css';
import 'swiper/css/scrollbar';
import './carousel.css';
import { useMediaDevice } from '../../hooks';

// install Swiper modules
SwiperCore.use([Scrollbar]);

interface CarouselProps {
  slidesPerView?: 'auto' | number;
  children: Array<ReactNode>;
}

const Carousel = ({ slidesPerView, children }: CarouselProps) => {
  const { isMobile, isTablet } = useMediaDevice();

  return (
    <Swiper
      scrollbar={{
        hide: false,
        draggable: true,
      }}
      slidesPerView={
        slidesPerView === 'auto' ? slidesPerView : isMobile || isTablet ? 1 : slidesPerView
      }
      spaceBetween={40}
    >
      {children.map((c, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SwiperSlide key={index}>{c}</SwiperSlide>
      ))}
      <div className="swiper-scrollbar" />
    </Swiper>
  );
};
export default Carousel;
