import styled from 'styled-components';
import React, { memo, useRef } from 'react';
import useOnScreen from '../../../../hooks/useOnScreen';

export const StarsWrapper = styled.div`
  position: absolute;
  width: 1451px;
  height: 1481px;
  top: -21%;
  left: -52%;
  pointer-events: none;
  z-index: -100;

  svg .svg-elem-1 {
    stroke-dashoffset: 4682.71435546875px;
    stroke-dasharray: 4682.71435546875px;
    -webkit-transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
    transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
  }

  svg.active .svg-elem-1 {
    stroke-dashoffset: 0;
  }

  svg .svg-elem-2 {
    stroke-dashoffset: 4687.73876953125px;
    stroke-dasharray: 4687.73876953125px;
    -webkit-transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
    transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
  }

  svg.active .svg-elem-2 {
    stroke-dashoffset: 0;
  }
`;

const StarSix = () => {
  const starRef = useRef<SVGSVGElement>(null);
  const isStarInView = useOnScreen(starRef);

  return (
    <StarsWrapper>
      <svg
        ref={starRef}
        className={isStarInView ? 'active' : ''}
        fill="none"
        viewBox="0 0 1451 1481"
        width="1451"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="svg-elem-1"
          d="M711.435 1429.89l-49.179-246.36H121.277l590.158-295.633L1104.87 1l-73.77 886.897 418.03 295.633H908.155l-196.72 246.36z"
          stroke="#EA9B3B"
        />
        <path
          className="svg-elem-2"
          d="M590.668 1478.1l.211 1.05.67-.84 196.57-246.17h542.311l-1.28-.91-417.8-295.466 73.746-886.612-.955-.244-393.367 886.733L.776 1231.19l.224.95h540.569l49.099 245.96z"
          opacity="0.21"
          stroke="#fff"
        />
      </svg>
    </StarsWrapper>
  );
};

export default memo(StarSix);
