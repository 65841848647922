import styled from 'styled-components';
import StyledLink from '../StyledLink';

interface MenuLinkProps {
  isactive: string;
}
export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    flex-direction: row;
    margin-bottom: 0;
  }
`;

export const MenuLink = styled(StyledLink)<MenuLinkProps>`
  margin: 20px;

  ${({ isactive }) =>
    isactive === 'true' &&
    `
      &::after {
        opacity: 1;
      }
      
  `}
}

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    margin: 0 20px;
  }
`;
