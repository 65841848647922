import styled from 'styled-components';

export const SectionContactWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-top: 1px ${({ theme }) => theme.palette.darkGrey} solid;
  padding: 40px 0 0;
  background-color: ${({ theme }) => theme.palette.black};
`;

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    margin: 40px 0 0;
  }
`;
