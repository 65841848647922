import { useStaticQuery, graphql } from 'gatsby';

export interface FeatureProps {
  title: string;
  value: string;
}
interface SectionSafetyQueryResults {
  contentfulSectionSafety: {
    title: string;
    features: Array<FeatureProps>;
  };
}

const GET_SECTION_SAFETY = graphql`
  query SectionSafety {
    contentfulSectionSafety {
      title
      features {
        title
        value
      }
    }
  }
`;

const useSectionSafetyQuery = () => {
  const { contentfulSectionSafety } = useStaticQuery<SectionSafetyQueryResults>(GET_SECTION_SAFETY);
  return contentfulSectionSafety;
};

export default useSectionSafetyQuery;
