import { useStaticQuery, graphql } from 'gatsby';

interface SectionWelcomeToTheFutureQueryResults {
  contentfulSectionWelcomeToTheFuture: {
    title: string;
    subtitle: string;
  };
}

const GET_SECTION_WELCOME_TO_THE_FUTURE = graphql`
  query SectionWelcomeToTheFuture {
    contentfulSectionWelcomeToTheFuture {
      title
      subtitle
    }
  }
`;

const useSectionWelcomeToTheFutureQuery = () => {
  const { contentfulSectionWelcomeToTheFuture } =
    useStaticQuery<SectionWelcomeToTheFutureQueryResults>(GET_SECTION_WELCOME_TO_THE_FUTURE);
  return contentfulSectionWelcomeToTheFuture;
};

export default useSectionWelcomeToTheFutureQuery;
