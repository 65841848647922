import styled from 'styled-components';

export const AccordionWrapper = styled.div`
  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    margin: 100px 0;
  }
`;

interface QuestionWrapperProps {
  isOpen: boolean;
  answerHeight: number;
  questionHeight: number;
}

export const QuestionWrapper = styled.div<QuestionWrapperProps>`
  border-bottom: 1px solid ${({ theme }) => theme.palette.darkGrey};
  max-height: ${({ questionHeight }) => questionHeight}px;
  transition: max-height 0.6s;
  overflow: hidden;
  cursor: pointer;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    padding: 0 30px;
  }

  ${({ isOpen, answerHeight, questionHeight }) =>
    isOpen &&
    `
    max-height: ${answerHeight + questionHeight + 20}px;
  `};
`;

export const QuestionTitle = styled.div`
  padding: 10px 0 28px;
  display: flex;
  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    padding: 15px 0 19px;
  } ;
`;

export const QuestionAnswer = styled.div`
  padding: 0 0 0 20px;
  border-left: 1px solid ${({ theme }) => theme.palette.orange};
  margin: 0 0 20px 25px;
`;

export const QuestionIcon = styled.span`
  width: 32px;
  margin-top: 2px;
`;

export const AccordionTitle = styled.h4`
  font-weight: 400;
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: 17px;
  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    font-size: 20px;
  } ;
`;
