import styled, { css } from 'styled-components';

export const headingCommon = (fontSize: number, lineHeight: number) => css`
  font-weight: 400;
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: ${fontSize * 0.65 >= 20 ? `calc(${fontSize}px * 0.65)` : '20px'};

  ${({ theme }) => theme.up(theme.breakpoints.sm)} {
    font-size: ${fontSize * 0.85 >= 20 ? `calc(${fontSize}px * 0.85)` : '20px'};
  }

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    font-size: ${fontSize}px;
    line-height: ${lineHeight}px;
  }

  sup {
    top: -1.5em;
    left: 0.25em;
    font-weight: 700;
    font-size: calc(${fontSize}px / 3);
  }
`;

export const H1 = styled.h1`
  ${headingCommon(96, 95)};
`;

export const H2 = styled.h2`
  ${headingCommon(40, 45)};
  line-height: 120%;
`;

export const H3 = styled.h3`
  ${headingCommon(28, 33)};
`;

export const H4 = styled.h4`
  ${headingCommon(18, 21)};
`;

export const H5 = styled.h5`
  ${headingCommon(16, 150)};
`;

export const H6 = styled.h6`
  ${headingCommon(14, 17)};
`;
