import styled from 'styled-components';

const Section = styled.div`
  padding: 25px 0 0;
  margin-bottom: 50px;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    padding: 80px 0 0;
    margin-bottom: 100px;
  }

  ${({ theme }) => theme.up(theme.breakpoints.lg)} {
    padding: 110px 100px 0;
  }
`;

export default Section;
