import React from 'react';
import { ContactUs, Heading } from '../../../components';
import { SectionContactWrapper, ContactWrapper } from './SectionContactUs.styled';
import { useSectionContactUsQuery } from '../../../hooks';

const SectionContactUs = () => {
  const { title, contacts } = useSectionContactUsQuery();
  const formattedContacts = contacts.map((contact) => ({
    ...contact,
    photo: contact.photo.file.url,
  }));

  return (
    <SectionContactWrapper>
      <Heading variant="h2">{title}</Heading>
      <ContactWrapper>
        {formattedContacts.map((contact) => (
          <ContactUs key={contact.name} {...contact} />
        ))}
      </ContactWrapper>
    </SectionContactWrapper>
  );
};

export default SectionContactUs;
