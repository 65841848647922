import React, { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { TopBar, Footer } from '../../components';
import './layout.css';
import theme from '../Theme';
import { AppContainer } from './Layout.styled';
import 'react-toastify/dist/ReactToastify.css';

interface LayoutProps {
  children: ReactNode;
  location: Location;
}

const Layout = ({ children, location }: LayoutProps) => {
  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
        <TopBar location={location} />
        {children}
        <Footer />
      </AppContainer>
    </ThemeProvider>
  );
};

export default Layout;
