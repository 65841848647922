import styled from 'styled-components';

export const PopupOverlay = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(9, 10, 10, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

interface PopupWrapperProps {
  autoWidth: boolean;
  autoHeight: boolean;
}

export const PopupWrapper = styled.div<PopupWrapperProps>`
  width: 100vw;
  height: 100vh;

  position: relative;
  z-index: 11;
  overflow: hidden;

  ${({ theme }) => theme.up(theme.breakpoints.sm)} {
    width: ${({ autoWidth }) => (autoWidth ? 'auto' : '100%')};
    height: ${({ autoHeight }) => (autoHeight ? 'auto' : '100%')};
    max-width: 90vw;
    max-height: 90vh;
  }
`;

export const PopupCloseWrapper = styled.div`
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  background: ${({ theme }) => theme.palette.orange};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
`;
