import styled from 'styled-components';
import React, { memo, useRef } from 'react';
import useOnScreen from '../../../../hooks/useOnScreen';

export const StarsWrapper = styled.div`
  position: absolute;
  width: 558px;
  height: 1126px;
  top: -19%;
  left: -100px;
  pointer-events: none;

  svg .svg-elem-1 {
    stroke-dashoffset: 3956.88427734375px;
    stroke-dasharray: 3956.88427734375px;
    -webkit-transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
    transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
  }

  svg.active .svg-elem-1 {
    stroke-dashoffset: 7913.7685546875px;
  }

  svg .svg-elem-2 {
    stroke-dashoffset: 3961.904052734375px;
    stroke-dasharray: 3961.904052734375px;
    -webkit-transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
    transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
  }

  svg.active .svg-elem-2 {
    stroke-dashoffset: 7923.80810546875px;
  }
`;

const StarOne = () => {
  const starRef = useRef<SVGSVGElement>(null);
  const isStarInView = useOnScreen(starRef);
  return (
    <StarsWrapper>
      <svg
        ref={starRef}
        className={isStarInView ? 'active' : ''}
        fill="none"
        height="100%"
        viewBox="0 0 620 1252"
        width="620"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="svg-elem-1"
          d="M-5.73093 1208.35L-47.2845 1000.19H-504.374L-5.73093 750.403L326.698 1.03662L264.368 750.403L617.573 1000.19H160.484L-5.73093 1208.35Z"
          stroke="#EA9B3B"
        />
        <path
          className="svg-elem-2"
          d="M-107.847 1249.1L-107.637 1250.15L-106.966 1249.31L59.0985 1041.34H515.948H517.521L516.236 1040.43L163.264 790.81L225.571 41.7283L224.615 41.4841L-107.741 790.687L-606.224 1040.4L-606 1041.34H-149.32L-107.847 1249.1Z"
          opacity="0.21"
          stroke="white"
        />
      </svg>
    </StarsWrapper>
  );
};

export default memo(StarOne);
