import React from 'react';
import { Heading, ReadMoreLink, RichTextRenderer } from '..';
import { ArrowLeft } from '../../assets/icons';
import { formatDate } from '../../helpers';
import { ArticleProps } from '../../hooks/api/useSectionArticlesQuery';
import {
  SingleArticleTileWrapper,
  SingleArticleImage,
  RichTextRendererWrapper,
} from './SingleArticleTile.styled';

const SingleArticleTile = ({ createdAt, mainImage, slug, content, title }: ArticleProps) => {
  return (
    <SingleArticleTileWrapper>
      <SingleArticleImage src={mainImage.file.url} />
      <Heading variant="h3">{title}</Heading>
      <Heading variant="h6">{formatDate(createdAt)}</Heading>
      <RichTextRendererWrapper>
        <RichTextRenderer>{content}</RichTextRenderer>
      </RichTextRendererWrapper>
      <ReadMoreLink to={`/blog/${slug}`}>
        Read More <ArrowLeft />
      </ReadMoreLink>
    </SingleArticleTileWrapper>
  );
};

export default SingleArticleTile;
