import styled from 'styled-components';

const Paragraph = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.palette.grey};
  font-size: 18px;
  line-height: 25px;
  font-family: Rosario, Bebas Neue, georgia, serif, sans-serif;
`;

export default Paragraph;
