import styled from 'styled-components';
import { PAGE_WIDTH } from '../../layouts/Theme';

interface SocialBarWrapperProps {
  isHorizontal: boolean;
}

export const SocialBarWrapper = styled.div<SocialBarWrapperProps>`
  position: ${({ isHorizontal }) => (isHorizontal ? 'static' : 'fixed')};
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  pointer-events: none;
  max-width: ${PAGE_WIDTH}px;
  margin: 0 auto;
  display: ${({ isHorizontal }) => (isHorizontal ? 'flex' : 'none')};
  padding: 0 30px;

  ${({ theme }) => theme.up(theme.breakpoints.lg)} {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

interface SocialBarInnerWrapperProps {
  isHorizontal: boolean;
}

export const SocialBarInnerWrapper = styled.div<SocialBarInnerWrapperProps>`
  height: ${({ isHorizontal }) => (isHorizontal ? 'auto' : '328px')};
  position: relative;
  pointer-events: auto;

  &::after {
    content: '';
    position: absolute;
    background: #090a0a;
    opacity: 0.8;
    top: -100vh;
    left: -50px;
    right: -50px;
    bottom: -100vh;
    display: ${({ isHorizontal }) => (isHorizontal ? 'none' : 'block')};
  }
`;

interface SocialIconsWrapperProps {
  isHorizontal: boolean;
}

export const SocialIconLink = styled.a`
  display: flex;
  position: relative;

  &:nth-of-type(1) {
    &::after {
      content: '';
      width: 1px;
      background: ${({ theme }) => theme.palette.white};
      height: 140px;
      top: -150px;
      left: 18px;
      position: absolute;
    }
  }

  &:nth-of-type(2) {
    margin: 30px 0;

    &::after,
    &::before {
      content: '';
      width: 1px;
      background: ${({ theme }) => theme.palette.white};
      height: 10px;
      left: 18px;
      position: absolute;
      pointer-events: none;
    }

    &::before {
      content: '';
      top: -20px;
    }

    &::after {
      content: '';
      bottom: -20px;
    }
  }

  &:nth-of-type(3) {
    &::after {
      content: '';
      width: 1px;
      background: ${({ theme }) => theme.palette.white};
      height: 140px;
      bottom: -150px;
      left: 18px;
      position: absolute;
      pointer-events: none;
    }
  }
`;

export const SocialIconsWrapper = styled.div<SocialIconsWrapperProps>`
  display: flex;
  flex-direction: ${({ isHorizontal }) => (isHorizontal ? 'row' : 'column')};
  pointer-events: auto;
  justify-content: center;
  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    justify-content: end;
  }

  ${({ isHorizontal }) =>
    isHorizontal &&
    `
    ${SocialIconLink} {
      &:nth-of-type(1) {
        &::after {
          display: none;
        }
      }
      &:nth-of-type(3) {
        &::after {
          display: none;
        }
      }
      &:nth-of-type(2) {
        margin: 0 30px;
    
        &::after,
        &::before {
          content: '';
          width: 10px;
          height: 1px;
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
        }
    
        &::before {
          content: '';
          left: -20px;
        }
    
        &::after {
          content: '';
          right: -20px;
          left: initial;
        }
      }
  `};
`;

export const SocialIcon = styled.img`
  border-radius: 50%;
  z-index: 2;
  width: 36px;
  height: 36px;

  &:hover {
    background: ${({ theme }) => theme.palette.orange};
  }
`;
