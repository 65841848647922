import { useStaticQuery, graphql } from 'gatsby';

interface TimelineItem {
  date: string;
  number: string;
  status: string;
  location: string;
}
interface BuildsForTimelineResults {
  contentfulBuilds: { [key: string]: Array<TimelineItem> };
}
const GET_SECTION_BUILDS_SLOTS = graphql`
  query BuildsForTimeline {
    contentfulBuilds {
      build2022 {
        date
        number
        status
        location
      }
      build2023 {
        date
        location
        number
        status
      }
    }
  }
`;

const useSectionBuildsSlots = () => {
  const { contentfulBuilds } = useStaticQuery<BuildsForTimelineResults>(GET_SECTION_BUILDS_SLOTS);
  return contentfulBuilds;
};

export default useSectionBuildsSlots;
