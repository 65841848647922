import { Link } from 'gatsby';
import styled from 'styled-components';

const ReadMoreLink = styled(Link)`
  color: ${({ theme }) => theme.palette.white};
  text-decoration: none;
  position: relative;
  font-size: 14px;
  line-height: 17px;
  margin-top: 30px;
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
  }
`;

export default ReadMoreLink;
