export const PAGE_WIDTH = 1650;

const theme = {
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: PAGE_WIDTH + 30,
  },
  palette: {
    black: '#000',
    white: '#fff',
    orange: '#EA9B3B',
    grey: '#C8C8C8',
    darkGrey: '#444444',
  },
  up: (breakpoint: number, vertical = false) =>
    `@media (min-${vertical ? 'height' : 'width'}: calc(${breakpoint}px + 1px))`,
  down: (breakpoint: number, vertical = false) =>
    `@media (max-${vertical ? 'height' : 'width'}: ${breakpoint}px)`,
  upDown: (breakpointUp: number, breakpointDown: number, vertical = false) =>
    `@media (min-${vertical ? 'height' : 'width'}: calc(${breakpointUp}px + 1px)) and (max-${
      vertical ? 'height' : 'width'
    }: ${breakpointDown}px)`,
} as const;

export default theme;

export type Theme = typeof theme;
